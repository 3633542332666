:root {
  --theme-color: #d2222b;
}

#car_container {   
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 10vh;
  margin-right: 10vh;
}

.buttons_cars {
  display: flex;
  gap: 1vh;  
  margin-top: 4vh;
}


:root {
  --surface-color: #fff;
  --curve: 40;
}

* {
  box-sizing: border-box;
}


body{
	margin:0;
	padding:0;
	font-family:"arial",heletica,sans-serif;
	font-size:12px;
  /* background: #2980b9 url('https://static.tumblr.com/03fbbc566b081016810402488936fbae/pqpk3dn/MRSmlzpj3/tumblr_static_bg3.png') repeat 0 0;
	-webkit-animation: 10s linear 0s normal none infinite animate;
	-moz-animation: 10s linear 0s normal none infinite animate;
	-ms-animation: 10s linear 0s normal none infinite animate;
	-o-animation: 10s linear 0s normal none infinite animate;
	animation: 10s linear 0s normal none infinite animate;*/
  color: #000000;
 
}
 
@-webkit-keyframes animate {
	from {background-position:0 0;}
	to {background-position: 500px 0;}
}
 
@-moz-keyframes animate {
	from {background-position:0 0;}
	to {background-position: 500px 0;}
}
 
@-ms-keyframes animate {
	from {background-position:0 0;}
	to {background-position: 500px 0;}
}
 
@-o-keyframes animate {
	from {background-position:0 0;}
	to {background-position: 500px 0;}
}
 
@keyframes animate {
	from {background-position:0 0;}
	to {background-position: 500px 0;}
}
.dialogbato{
  font-size: x-large;
}
.dialogbato h3 {
  margin-left: 1vh;
}
.dialogbato p {
  margin-left: 2vh;
}
.crno{
  color: black;
}


.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Svaka kartica će imati minimalnu širinu od 250px */
  gap: 20px;  /* Razmak između kartica */
  padding: 20px;
  justify-content: center;  /* Centriranje kartica kada ih je manje */
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border: 2px solid #000000; /* Border oko cijele kartice */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: 250px; /* Fiksna širina kartica */
  min-height: 350px; /* Fiksna visina kartica */
}


.card__image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-bottom: 1px solid #ccc;
}

.card__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 20px;
  text-align: center;
}

.card__title {
  font-size: 1.5rem;
  margin: 0;
  color: black;
}

.card__description {
  font-size: 1rem;
  color: black;
  min-height: 80px; /* Dovoljno prostora za sav opis */
  text-overflow: ellipsis;
}

.card__buttons {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background-color: var(--theme-color); /* Sekcija gumba u narančastoj boji */
  border-top: 1px solid #ccc;
}

.button {
  padding: 10px;
  background-color: #333;
  color: white;
  border: 1px solid #000000;
  border-radius: 5px;
  cursor: pointer;
}

.button:hover {
  background-color: #767676;
}

.bold-label {
  font-weight: bold; /* Samo ključne riječi Opis, Vrsta, Šifra su podebljane */
}

.filter-buttons button.active {
  background-color: var(--theme-color); /* Postavlja boju pozadine na narančastu */
  color: #fff; /* Postavlja boju teksta na bijelu */
  border-color: var(--theme-color); /* Postavlja boju granice na narančastu */
}
