:root {
    --theme-color: #d2222b; /* Theme color */
    --hover-light: #ee1c26; /* Slightly lighter shade for hover */
    --hover-dark: rgb(107, 6, 6);  /* Darker shade for logout hover */
}

/* Navigation bar styles */
.navbar {
    background-color: white;
    border-bottom: 2px solid #e0e0e0;
    padding: 15px;

    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
}



/* Logo positioning */
.logo-link {
    margin-left: 20px;
}

.logo {
    height: 50px;
}

/* Center navigation links container */
.nav-links-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;  /* Center the nav buttons */
}

.nav-links {
    display: flex;
    gap: 15px;
}

/* Navigation buttons */
.nav-button {
    background-color: var(--theme-color);
    color: white;
    border: none;
    border-radius: 12px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Hover effects */
.nav-button:hover {
    background-color: var(--hover-light);
}

/* Logout button specific hover */
.logout-button:hover {
    background-color: var(--hover-dark);
}




/* Navbar.css */

/* Existing styles... */

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 30px;
    max-width: 500px;
    width: 90%;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  }
  
  .overlay {
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .modal h2 {
    font-size: 28px;
    margin-bottom: 25px;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 25px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 10px;
    font-size: 20px; /* Larger font size for labels */
    font-weight: bold;
    color: #333;
  }
  
  .form-group input {
    width: 100%;
    padding: 14px;
    font-size: 18px; /* Larger font size for inputs */
    border: 2px solid #ccc; /* Thicker border */
    border-radius: 6px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
  }
  
  .form-group input:focus {
    border-color: #4CAF50; /* Green border on focus */
    outline: none;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
  
  .save-button {
    background-color: #4CAF50;
    color: white;
    padding: 12px 24px;
    margin-right: 10px;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .cancel-button {
    background-color: #f44336;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .save-button:hover,
  .cancel-button:hover {
    opacity: 0.9;
  }
  
  .nav-buttons-right {
    display: flex;
    align-items: center;
  }
  
  .settings-button {
    margin-left: 10px;
  }
  