/* Podcrtavanje naslova */
.naslovi {
    text-decoration: underline;
    font-weight: bold;
    text-align: center;
    color: black;
    font-size: 28px;
    margin-bottom: 20px;
}

/* Stilovi za kartice */
.pocetak-card, .kraj-card {
    font-size: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    min-height: 200px;
    width: 100%;
    border: 2px solid #d3d3d3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Početak kartice imaju zelenu boju obruba */
.pocetak-card {
    border: 3px solid green;
}

/* Kraj kartice imaju crvenu boju obruba */
.kraj-card {
    border: 3px solid red;
}

/* Stilovi za slike automobila */
.car-image {
    width: 140px;
    height: auto;
    max-height: 150px;
    object-fit: contain;
    margin-left: 20px;
}

/* Crta između sekcija */
.vertical-line {
    width: 3px;
    background-color: black;
    margin: 0 30px;
    display: inline-block;
}

/* Wrapper za početak i kraj sekciju */
.dnevni-plan-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

/* Početak i kraj sekcije širine 50% */
.pocetak-wrapper, .kraj-wrapper {
    width: calc(50% - 35px);
}

/* Responsivno ponašanje */
@media (max-width: 768px) {
    .pocetak-card, .kraj-card {
        flex-direction: column;
        align-items: flex-start;
    }

    .car-image {
        margin-left: 0;
        margin-top: 10px;
    }

    .vertical-line {
        display: none;
    }

    .pocetak-wrapper, .kraj-wrapper {
        width: 100%;
    }
}

/* Stilovi za datum odabira */
.date-picker-container {
    text-align: center;
    margin-bottom: 20px;
}

.date-picker-container label {
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
}

.date-picker-container input[type="date"] {
    padding: 8px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.date-picker-filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.filter-buttons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.date-picker-container input {
    padding: 10px;
    border: 1px solid gray;
    border-radius: 4px;
}
